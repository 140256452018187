latlng = new google.maps.LatLng(59.95428,30.251334);
myOptions =
	zoom: 12
	center: latlng
	zoomControl: true
	scaleControl: false
	scrollwheel: false
	disableDoubleClickZoom: true
	navigationControl: false
	mapTypeControl: false
	scaleControl: false
	draggable: true
	disableDefaultUI: true
	mapTypeId: google.maps.MapTypeId.ROADMAP

map = new google.maps.Map(document.getElementById("map"),
	myOptions);
point1 = new google.maps.LatLng(59.95428,30.251334);
marker = new google.maps.Marker(
	position: point1
	map: map
	title: 'inek'
	draggable:true
	icon: ''
)

latlng = new google.maps.LatLng(59.95428,30.251334);
myOptions =
	zoom: 12
	center: latlng
	zoomControl: true
	scaleControl: false
	disableDoubleClickZoom: true
	scrollwheel: false
	navigationControl: false
	mapTypeControl: false
	scaleControl: false
	draggable: true
	disableDefaultUI: true
	mapTypeId: google.maps.MapTypeId.ROADMAP

map = new google.maps.Map(document.getElementById("map2"),
	myOptions);
point1 = new google.maps.LatLng(59.95428,30.251334);
marker = new google.maps.Marker(
	position: point1
	map: map
	title: 'inek'
	draggable:true
	icon: ''
)



# $(document).ready ->

# 	$('.form').validate
# 		rules:
# 			name:
# 				required: true
# 				minlength: 4
# 			errorPlacement: (error, element) ->
# 				true