class SliderTabs

  # Global variables
  tabsWrap      = null
  activeTab     = null
  popup         = null
  closePopup    = null
  imagePopup    = null
  prevBtnPopup  = null
  nextBtnPopup  = null
  activeThumbs  = null
  curTabs       = 0
  curView       = 0

  constructor: (el) ->
    @el = $(el)
    do @init
  init: ->
    @tabs         = @el.find '.tabs-button li'
    tabsWrap      = @el.find '.tabs-contents'
    @tabsContents = tabsWrap.find '.tab-block'
    do @events
    do @showTabs
    do @buildPopup
    do @initSlider

  events: ->
    self = this
    @tabs.on 'click', ->
      self.showTabs($(this).index())

  showTabs: (i) ->
    @tabs.eq(curTabs).removeClass 'active'
    @tabsContents.eq(curTabs).removeClass 'active'

    curTabs = if i != undefined then i else 0
    @tabs.eq(curTabs).addClass 'active'
    @tabsContents.eq(curTabs).addClass 'active'
    activeTab = @tabsContents.eq(curTabs)
    activeThumbs  = activeTab.find('.thumb')

  buildPopup: ->
    $('body').append '
      <div class="popup" id="popup">
        <span class="arrow left"></span>
        <img src="">
        <span class="arrow right"></span>
        <span class="close"></span>
      </div>'

    # Popup variables
    popup         = $('#popup')
    closePopup    = popup.find '.close'
    imagePopup    = popup.find 'img'
    prevBtnPopup  = popup.find '.left'
    nextBtnPopup  = popup.find '.right'

    do @eventPopoups

  eventPopoups: ->

    changeView = (el) ->
      el.attr('src', activeThumbs.eq(curView).data('large'))

    closePopup.on 'click', ->
      popup.removeClass 'show'

    prevBtnPopup.on 'click', ->
      curView--
      if curView <= 0
        curView = activeThumbs.length - 1
      changeView(imagePopup)

    nextBtnPopup.on 'click', ->
      curView++
      if curView >= activeThumbs.length - 1
        curView = 0
      changeView(imagePopup)


  initSlider: ->

    class Slider
      constructor: (el) ->
        @el = $(el)
        do @init
        do @responsive
        do @buildCarusel if @length >= 8
        do @eventsView
        do @changeDescr


      init: ->
        # Common variables
        @imageWrap      = @el.find '.view'
        @thumbsWrap     = @el.find '.thumbs'
        @thumbsCarusel  = @thumbsWrap.find '.carusel'
        @thumbs         = @thumbsWrap.find '.thumb'
        @length         = @thumbs.length
        @image          = @imageWrap.find 'img'
        @descr          = @el.find '.description ul li'
        @descrCur       = 0

      responsive: ->
        width = tabsWrap.width()
        @widthThumb = (width - 84) / 8

        setWidth = (element, pixels) ->
          element.css('width', pixels + 'px')

        setWidth(@imageWrap, width)
        setWidth(@thumbsWrap, width)
        setWidth(@thumbs, @widthThumb)
        setWidth(@thumbsCarusel, @length * @widthThumb + @length * 12)
      buildCarusel: ->
        @thumbsWrap.before '
          <div class="arrows">
              <div class="arrow left"></div>
              <div class="arrow right"></div>
          </div>'

        @arrows = @thumbsWrap.siblings('.arrows')
        @leftArrow = @arrows.find('.left')
        @rightArrow = @arrows.find('.right')
        @posCarusel = 0

        do @eventsCarusel
      eventsCarusel: ->
        @rightArrow.on 'click', =>
          @posCarusel++
          if @posCarusel >= @length - 8 then @posCarusel = @length - 8
          do @moveCarusel

        @leftArrow.on 'click', =>
          @posCarusel--
          if @posCarusel <= 0 then @posCarusel = 0
          do @moveCarusel
      moveCarusel: ->
        step = (@widthThumb + 12) * @posCarusel
        @thumbsCarusel.css(Modernizr.prefixed('transform'), "translate3d(-#{step}px,0,0)")

      eventsView: ->
        self = this

        @thumbs.on 'click', ->

          curView = $(this).index()
          self.image.attr('src', activeThumbs.eq(curView).data('large'))
          do self.changeDescr

        @imageWrap.on 'click', ->
          popup.addClass 'show'
          imagePopup.attr('src', activeThumbs.eq(curView).data('large'))

      changeDescr: ->
        @descr.eq(curView).addClass('active').siblings('li').removeClass('active')


    for val in @tabsContents
      new Slider(val)


$ ->
  new SliderTabs('#tabs')



